import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About | Two Thumbs Up</title>
      </Helmet>
      <Layout isHome={false}>
        <article className="prose">
          <h1>Two Thumbs Up is a DFW-based construction and remodeling company.</h1>
          <p>
            With more than 18 years of homebuilding experience and a reputation for quality
            construction and unrivaled design we're ready to tackle any project! That's why so many
            trust us to make their homes new again.
          </p>
          <p>
            At Two Thumbs Up we're committed to our craftsmanship, as well as delivering an
            above-and-beyond client experience. When you work with us, you have a dedicated team to
            guide you through each phase of your project, offering insights and expertise along the
            way. Together, we'll put together a plan for your home based on your vision, needs,
            style preferences, and goals. You'll be amazed at how Two Thumbs Up can add value to
            your home and, in turn, improve your quality of life.
          </p>
          <p>
            Two Thumbs Up is owned by Stefan Inocan, who has been a Texas resident since 1997. He is
            originally from Turda, Cluj (Transylvania), Romania, Europe.
          </p>
        </article>
      </Layout>
    </>
  );
}
